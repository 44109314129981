.container {
  text-align: center;
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.logo {
  width: 100px; /* Adjust size as needed */
  margin-bottom: 15px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.description {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.storeButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.storeButton:hover {
  background-color: #333;
}

.icon {
  font-size: 20px;
}

.infoSection {
  text-align: left;
  margin-top: 20px;
}

h3 {
  font-size: 18px;
  color: #444;
  margin-bottom: 5px;
}

p {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.email {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.email:hover {
  text-decoration: underline;
}

.credits {
  margin-top: 20px;
  font-size: 12px;
  color: #888;
  text-align: center;
}

.credits a {
  color: #007bff;
  text-decoration: none;
}

.credits a:hover {
  text-decoration: underline;
}
